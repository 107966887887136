import { ConsumptionBasedProductPrice } from '../ConsumptionBasedProductPrice';

export class ApplicationItem {
  public static createEmpty(): ApplicationItem {
    return new ApplicationItem(0);
  }
  constructor(
    public Id: number,
    public Name: string = '',
    public LogoUri: string = '',
    public StartBackgroundColor: string = '',
    public StartForegroundColor: string = '',
    public StartBackgroundImageUri: string = '',
    public TopNavForegroundColor: string = '',
    public TopNavBackgroundColor: string = '',
    public MenuBackgroundColor: string = '',
    public MenuForegroundColor: string = '',
    public EnableProgramQuestion: boolean = false,
    public IconUrl: string = '',
    public CreateAccountType: string = '',
    public CreateAccountTypeId: number = 0,
    public ConsumedAdmins: number = 0,
    public ConsumedEvents: number = 0,
    public IsCurrentPackageTrial: boolean = false,
    public PackageExirationPendingDays: number = 0,
    public IsRegistrationPFActive: boolean = false,
    public AllowEnableTimeZoneFeature: boolean = false,
    public EnableVirtualMeetingIntegration: boolean = false,
    public EnableIntegrateteamszoomWatchLive: boolean = false,
    public EnableCustomOAuthAuthorization: boolean = false,
    public IsApplicationOnNewPackage: boolean = false,
    public IsApplicationOnOldPackage: boolean = false,
    public EnableVimeoVideos: boolean = false,
    public EnableRankingDialogue: boolean = false,
    public EnableMeetAppLive: boolean = false,
    public EnableMeetAppLiveArchiving: boolean = false,
    public EnableScalingDialogue: boolean = false,
    public ConsumptionBasedProductPricing?: ConsumptionBasedProductPrice,
    public AllowEnableNewAdmin: boolean = false,
    public EnableVideoOnDemand: boolean = false,
    public EnableLinkProgramItemsToGroup: boolean = false,
    public EnableLinkParticipantToInformation: boolean = false,
    public EnableVideoOnDemandCustomCategory: boolean = false,
    public IsLiveStreamingActive: boolean = false,
    public Departments?: [],
    public VerifyThresholdLimit: boolean = false,
    public IsThresholdLimitReached: boolean = false,
    public IsDepartmentAdminFeatureEnable: boolean = false,
    public ThrehsholdEventsNumber: number = 0,
    public MaxAllowedAppInstances: number = 0,
    public CurrentEventsNumber: number = 0,
    public TotalConsumedEvents: number = 0,
    public EnableRTMPOnEventSettings: boolean = false,
    public IsParticipantSelfCheckInActive: boolean = false,
    public LogoUriDarkMode: string = '',
    public EnableDialogueThemes: boolean = false,
    public EnableUserEditedParticipant: boolean = false,
    public EnableCustomMenuNames: boolean = false,
    public EnableEventWebPage: boolean = false,
    public EnableCustomBranding: boolean = false,
    public IsEventWebPagePFActive: boolean = false,
    public IsZapierPFActive: boolean = false,
    public ZapierKey: string = '',
    public IsZapierAvailableInApplicationZapierCallback: boolean = false,
  ) {
  }
}

