export class AppRequestInfo {
  public static createEmpty(): AppRequestInfo {
    return new AppRequestInfo(0, 0, '', '', '', '', '', '', '', '', '', '', '');
  }
  constructor(
    public ApplicationId: number = 0,
    public ApplicationInstanceId: number = 0,
    public Authorization: string = '',
    public UserId: string = '',
    public Username: string = '',
    public AdminType: string = '',
    public EnvironmentBaseURL: string = '',
    public SignalRBaseURL: string = '',
    public FirstName: string = '',
    public MuxEnvironmentKey: string = '',
    public LastName: string,
    public UserEmail: string,
    public UserHash: string,
    public AllowEnableTimeZoneFeature: boolean = false,
    public EnableVirtualMeetingIntegration: boolean = false,
    public EnableIntegrateteamszoomWatchLive: boolean = false,
    public EnableVimeoVideos: boolean = false,
    public EnableRankingDialogue: boolean = false,
    public EnableMeetAppLive: boolean = false,
    public EnableMeetAppLiveArchiving: boolean = false,
    public EnableScalingDialogue: boolean = false,
    public EnableVideoOnDemand: boolean = false,
    public IsCurrentPackageTrial: boolean = false,
    public EnableParticipantList: boolean = false,
    public EnableLinkProgramItemsToGroup: boolean = false,
    public EnableLinkParticipantToInformation: boolean = false,
    public EnableVideoOnDemandCustomCategory: boolean = false,
    public SelectedMode: string = '',
    public isApplicationLegacyModel: boolean = false,
    public VerifyThresholdLimit: boolean = false,
    public IsThresholdLimitReached: boolean = false,
    public IsApplicationOnNewPackage: boolean = false,
    public IsApplicationAdmin: boolean = false,
    public IsDepartmentAdmin: boolean = false,
    public IsEventAdmin: boolean = false,
    public IsSuperAdmin: boolean = false,
    public IsDepartmentAdminFeatureEnable: boolean = false,
    public IsLiveStreamingActive: boolean = false,
    public ThrehsholdEventsNumber: number = 0,
    public MaxAllowedAppInstances: number = 0,
    public CurrentEventsNumber: number = 0,
    public TotalConsumedEvents: number = 0,
    public AdditionalEvents: number = 0,
    public DepartmentId: number = 0,
    public CreateAccountType: string = '',
    public CreateAccountTypeId: number = 0,
    public IsRegistrationPFActive: boolean = false,
    public EnableRTMPOnEventSettings: boolean = false,
    public IsParticipantSelfCheckInActive: boolean = false,
    public LogoURI: string = '',
    public LogoUriDarkMode: string = '',
    public EnableDialogueThemes: boolean = false,
    public EnableUserEditedParticipant: boolean = false,
    public EnableCustomMenuNames: boolean = false,
    public EnableEventWebPage: boolean = false,
    public EnableCustomBranding: boolean = false,
    public IsEventWebPagePFActive: boolean = false,
    public IsZapierPFActive: boolean = false,
    public ZapierKey: string = '',
    public IsZapierAvailableInApplicationZapierCallback: boolean = false,
  ) { }
}
